import {
  CLIENT_CONNECTIVITY_STATUS,
  getPerformedTaskNames
} from '@/state/signify'
import useStore from '@/state/store'
import { Spinner } from 'flowbite-react'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Button } from '../base/Button'
import ModalComponent from '../base/Modal'
import Eye from '../icons/Eye'
import Toast from '../base/Toast'
import { FormattedMessage } from 'react-intl'
import { login } from '@/utils/auth'
import { head } from 'lodash'
import { isValidPasscode } from '@/validations/auth'
import { emailService } from '@/utils/apis'

const Footer = ({
  userPassCode,
  validationError
}: {
  userPassCode: string
  validationError: Dispatch<SetStateAction<boolean>>
}) => {
  const {
    clientConnectivityStatus,
    authentication: { userInfo },
    setForcedClose,
    connectToAgent,
    setClientConnectivityStatus
  } = useStore()
  const [submitting, setSubmitting] = useState(false)

  useEffect(() => {
    setSubmitting(
      clientConnectivityStatus === CLIENT_CONNECTIVITY_STATUS.CONNECTING
    )
  }, [clientConnectivityStatus])

  const checkPasscodeConnectWallet = async () => {
    if (isValidPasscode(userPassCode)) {
      try {
        validationError(false)
        setClientConnectivityStatus(CLIENT_CONNECTIVITY_STATUS.CONNECTING)
        const originAid = await emailService.getUserOriginAidByEmail({
          email: head(userInfo.emails).value
        })
        await login(head(userInfo.emails).value, userPassCode)
        await connectToAgent(userPassCode, originAid.aid)
      } catch (ex) {
        setClientConnectivityStatus(CLIENT_CONNECTIVITY_STATUS.ERROR)
      }
      setForcedClose(false)
    } else {
      validationError(true)
    }
  }

  return (
    <div className="flex flex-row-reverse">
      <Button
        label={
          <>
            <span className="flex items-center gap-2">
              {submitting && <Spinner color="info" aria-label="loading..." />}
              <>
                <FormattedMessage id="cta.confirm" />
              </>
            </span>
          </>
        }
        onClick={async () => await checkPasscodeConnectWallet()}
        disabled={userPassCode.length != 21 || submitting}
      />
    </div>
  )
}

const SignifyView = () => {
  const {
    promptForPassCode,
    hidePassCodePrompt,
    setForcedClose,
    clientConnectivityStatus,
    signifyTaskPerformed
  } = useStore()
  const [showCode, setShowCode] = useState(false)
  const [userPassCode, setUserPassCode] = useState('')
  const [validationError, setValidationError] = useState(false)

  useEffect(() => {
    if (!promptForPassCode) return

    setUserPassCode('')
  }, [promptForPassCode])

  return (
    <>
      {clientConnectivityStatus === CLIENT_CONNECTIVITY_STATUS.ERROR &&
        promptForPassCode && (
          <Toast mode="ERROR">
            <FormattedMessage id="error.agentConnectionFailed" />
          </Toast>
        )}
      {validationError && promptForPassCode && (
        <Toast mode="ERROR">
          <FormattedMessage id="error.invalidPasscode" />
        </Toast>
      )}

      {signifyTaskPerformed.length > 0 && (
        <Toast mode="INFO">
          <FormattedMessage id="common.label.performedTask" />{' '}
          {getPerformedTaskNames(signifyTaskPerformed).join(', ')}
        </Toast>
      )}
      <ModalComponent
        show={promptForPassCode}
        dataTestId="passcode-modal"
        header={
          <>
            <FormattedMessage id="common.label.yourPassCode" />
            <p className="mt-2 text-base font-normal text-neutralsAdditional800">
              <FormattedMessage id="common.label.description" />
            </p>
          </>
        }
        onClose={() => {
          hidePassCodePrompt()
          setForcedClose(true)
        }}
        size="lg"
        className="z-[999]"
        footer={
          <Footer
            userPassCode={userPassCode}
            validationError={setValidationError}
          />
        }
      >
        <div>
          <label htmlFor="confirmControlCode" className={`text-sm font-normal`}>
            <FormattedMessage id="page.generic.enterPasscode" />
          </label>
          <div
            className={`flex justify-between items-center border border-neutralsAdditional400 rounded-md px-3 py-[10px] mt-1`}
          >
            <input
              type={showCode ? 'text' : 'password'}
              placeholder="Enter passcode"
              className="w-full p-0 font-mono text-2xl border-none focus:ring-0 placeholder:font-sans placeholder:text-base"
              onChange={(e) => setUserPassCode(e.currentTarget.value)}
              value={userPassCode}
              maxLength={21}
            />
            <Eye
              className="cursor-pointer"
              show={!showCode}
              onClick={() => setShowCode(!showCode)}
            />
          </div>
        </div>
      </ModalComponent>
    </>
  )
}

export default SignifyView
