import constants, { FETCHING_STATUS } from '@/config/constants'
import {
  createIdentifier,
  deleteIdentifier,
  generateChallenge,
  getContact,
  getContacts,
  getCredentials,
  getIdentifier,
  getIdentifiers,
  getKeyState,
  getNotifications,
  getOobis,
  getRegistries,
  initClient,
  issueCredential,
  renameIdentifier,
  resolveOobi
} from '@/services/signify'
import { GroupMember } from '@/services/wallet/types'
import { IOOBI, Registries } from '@/types/SignifyTypes'
import { produce } from 'immer'
import { isEqual, uniq } from 'lodash'
import { SignifyClient } from 'signify-ts'
import { StateCreator } from 'zustand'
import { IStore } from '@/state/store'

export enum CLIENT_CONNECTIVITY_STATUS {
  CONNECTING = 'CONNECTING',
  CONNECTED = 'CONNECTED',
  DISCONNECTED = 'DISCONNECTED',
  ERROR = 'ERROR'
}

interface ITask {
  operation: keyof IActions
  payload?: { [key: string]: any }
}

export interface Identifier {
  name: string
  prefix: string
  isMultisig: boolean
  group?: GroupAid
  raw?: any
  type?: string
}

export interface GroupAid {
  name: string
  prefix: string
  localAid: {
    name: string
    prefix: string
  }
  members?: GroupMember[]
  minSignersThreshold: number
  witnessOobi?: string
}

interface IChallenge {
  dt: string
  words: string[]
  said: string
  authenticated: boolean
}

export interface IContact {
  id: string
  alias: string
  oobi: string
  challenges: IChallenge[]
}

interface registry {
  regk: string
}

export enum KNotificationType {
  ICP = '/multisig/icp',
  IXN = '/multisig/ixn',
  ROT = '/multisig/rot',
  RPY = '/multisig/rpy',
  VCP = '/multisig/vcp',
  ISS = '/multisig/iss',
  EXN = '/multisig/exn',
  GRANT = '/exn/ipex/grant',
  REV = '/multisig/rev',
  ADMIT = '/exn/ipex/admit'
}

interface KNotification {
  i: string
  dt: string
  r: boolean
  a: {
    r: KNotificationType
    d: string
  }
}

interface IState {
  clientConnectivityStatus: CLIENT_CONNECTIVITY_STATUS
  notificationProcessingStatus: string[]
  passCodeLifeTime: number
  passCode: string
  timeoutIDs: {
    signifyTimeOutId: NodeJS.Timeout | null
    toastTimeOutId: NodeJS.Timeout | null
  }
  promptForPassCode: boolean
  forcedClose: boolean
  signifyTaskQueue: ITask[]
  signifyTaskPerformed: (keyof IActions)[]
  signifyClient: SignifyClient | null
  aids: {
    fetchingStatus: FETCHING_STATUS
    identifiers: Identifier[]
  }
  aid: Identifier
  notifications: {
    fetchingStatus: FETCHING_STATUS
    notifications: {
      start: number
      end: number
      total: number
      notes: KNotification[]
    }
  }
  contacts: {
    fetchingStatus: FETCHING_STATUS
    contacts: IContact[]
  }
  registries: {
    fetchingStatus: FETCHING_STATUS
    registries: registry[]
  }
}

interface IActions {
  connectToAgent: (passCode: string, originAid?: string) => Promise<boolean>
  setClientConnectivityStatus: (
    clientConnectivityStatus: CLIENT_CONNECTIVITY_STATUS
  ) => void
  getIdentifiers: () => Promise<Identifier[]>
  getIdentifierByAlias: (alias: string) => Promise<any>
  getNotifications: (last?: number, limit?: number) => void
  addNotificationProcess: (process: string) => void
  removeNotificationProcess: (process: string) => void
  getContacts: (
    group?: string,
    filterField?: string,
    filterValue?: string
  ) => Promise<IContact[]>
  getContact: (identifier: string) => Promise<any>
  getKeyState: (identifier: string) => void
  getRegistries: (name: string) => Promise<Registries[]>
  getOOBIS: (alias: string, role: string) => Promise<IOOBI>
  resolveOOBIS: (oobi, alias) => Promise<any>
  issueCredential: (
    alias: string,
    registry: string,
    recipient: string,
    schema: string,
    credentialData: any,
    rules: any | undefined,
    source: any | undefined,
    _private?: boolean
  ) => any
  createIdentifier: (alias: string, witnessAids: string[]) => Promise<any>
  deleteIdentifier: (alias: string) => Promise<any>
  renameIdentifier: (alias: string, newAlias: string) => Promise<any>
  getCredentials: (filter: object) => Promise<any[]>
  generateChallenge: () => Promise<string>
  updateIdentifier: (updatedIdentifier: Identifier) => void
}

export type ISignify = IState &
  IActions & {
    setPassCodeLifeTime: (passCodeLifeTime: number) => void
    showPassCodePrompt: () => void
    hidePassCodePrompt: () => void
    setForcedClose: (value: boolean) => void
    clearSignifyTaskPerformed: () => void
    clearSignify: () => void
    _passcodePrompter: (
      operation: keyof IActions,
      payload: any,
      cb: () => Promise<any>
    ) => Promise<any>
    resetSignifyStore: () => void
  }

let client: SignifyClient | null = null

const resetTimeOut = (
  set: (
    partial: (state: ISignify) => ISignify | Partial<ISignify>,
    replace?: boolean | undefined
  ) => void,
  get: () => ISignify
) => {
  get().timeoutIDs.signifyTimeOutId &&
    clearTimeout(get().timeoutIDs.signifyTimeOutId as NodeJS.Timeout)
  const newTimeOutId = setTimeout(() => {
    get().clearSignify()
    console.log('DISCONNECTING THE CLIENT', new Date())
  }, get().passCodeLifeTime)
  set(
    produce((state: ISignify) => {
      state.timeoutIDs.signifyTimeOutId = newTimeOutId
    })
  )

  get().timeoutIDs.toastTimeOutId &&
    clearTimeout(get().timeoutIDs.toastTimeOutId as NodeJS.Timeout)
  const newToastTimeoutId = setTimeout(() => {
    set(
      produce((state: ISignify) => {
        ;(state.signifyTaskPerformed = []),
          (state.timeoutIDs.toastTimeOutId = null)
      })
    )
  }, constants.TOAST_DISPLAY_TIME)
  set(
    produce((state: ISignify) => {
      state.timeoutIDs.toastTimeOutId = newToastTimeoutId
    })
  )
}

export const getPerformedTaskNames = (
  signifyTaskPerformed: (keyof IActions)[]
) => {
  const result: string[] = []
  const operationNameLiteral: { [key in keyof Partial<IActions>]: string } = {
    getIdentifiers: 'Fetch Identifiers',
    getIdentifierByAlias: 'Fetch Identifier by Alias',
    getNotifications: 'Fetch Notification',
    getContacts: 'Fetch Contacts',
    getContact: 'Fetch Contact detail',
    getKeyState: 'Fetch key state',
    getRegistries: 'Fetch registries',
    issueCredential: 'Issue credential',
    createIdentifier: 'Create Identifier'
  }

  uniq(signifyTaskPerformed).forEach((task) => {
    if (task in operationNameLiteral) {
      result.push(operationNameLiteral[task] as string)
    }
  })

  return result
}

const initialState: IState = {
  clientConnectivityStatus: CLIENT_CONNECTIVITY_STATUS.DISCONNECTED,
  notificationProcessingStatus: [],
  passCodeLifeTime: constants.PASS_CODE_LIFETIME,
  passCode: '',
  timeoutIDs: {
    signifyTimeOutId: null,
    toastTimeOutId: null
  },
  promptForPassCode: false,
  forcedClose: false,
  signifyTaskQueue: [],
  signifyTaskPerformed: [],
  aids: {
    fetchingStatus: FETCHING_STATUS.NOT_INITIATED,
    identifiers: []
  },
  aid: null,
  signifyClient: null,
  notifications: {
    fetchingStatus: FETCHING_STATUS.NOT_INITIATED,
    notifications: {
      start: 0,
      end: 0,
      total: 0,
      notes: []
    }
  },
  contacts: {
    fetchingStatus: FETCHING_STATUS.NOT_INITIATED,
    contacts: []
  },
  registries: {
    fetchingStatus: FETCHING_STATUS.NOT_INITIATED,
    registries: []
  }
}

const signifyStore: StateCreator<IStore, [], [], ISignify> = (set, get) => ({
  ...initialState,
  connectToAgent: async (passCode: string, originAid?: string) => {
    let connected = false
    set(() => ({
      clientConnectivityStatus: CLIENT_CONNECTIVITY_STATUS.CONNECTING
    }))
    try {
      client = await initClient(passCode, originAid)
      set(
        produce((state: ISignify) => {
          ;(state.clientConnectivityStatus =
            CLIENT_CONNECTIVITY_STATUS.CONNECTED),
            (state.promptForPassCode = false),
            (state.passCode = passCode),
            (state.aids.fetchingStatus = FETCHING_STATUS.NOT_INITIATED)
          state.aids.identifiers = []
          state.signifyClient = client
        })
      )

      const execFunctions = get().signifyTaskQueue.map(async (task) => {
        const params = Object.values(task.payload)
        // @ts-ignore
        await get()[task.operation](...params)
      })
      Promise.all(execFunctions)

      set(
        produce((state: ISignify) => {
          state.signifyTaskQueue = []
        })
      )
      connected = true
      resetTimeOut(set, get)
    } catch (ex) {
      console.log('ERROR CONNECTING AGENT', ex)
      set(() => ({
        clientConnectivityStatus: CLIENT_CONNECTIVITY_STATUS.ERROR
      }))
    }
    return connected
  },
  setClientConnectivityStatus: (
    clientConnectivityStatus: CLIENT_CONNECTIVITY_STATUS
  ) => {
    set(() => ({ clientConnectivityStatus }))
  },
  showPassCodePrompt: () => {
    set(() => ({ promptForPassCode: true }))
  },
  hidePassCodePrompt: () => {
    set(() => ({ promptForPassCode: false, signifyTaskQueue: [] }))
  },
  setForcedClose(value) {
    set(() => ({ forcedClose: value }))
  },
  clearSignifyTaskPerformed: () => {
    set(() => ({ signifyTaskPerformed: [] }))
  },
  setPassCodeLifeTime: (passCodeLifeTime) => {
    set(() => ({ passCodeLifeTime }))
  },
  _passcodePrompter: async (operationName, payload, callback) => {
    let result
    if (
      get().clientConnectivityStatus !== CLIENT_CONNECTIVITY_STATUS.CONNECTED
    ) {
      get().showPassCodePrompt()

      const operationAlreadyQueued =
        get().signifyTaskQueue.filter(
          (stq) =>
            stq.operation === operationName && isEqual(stq.payload, payload)
        ).length > 0
      if (!operationAlreadyQueued)
        set(
          produce((state: ISignify) => {
            state.signifyTaskQueue.push({ operation: operationName, payload })
          })
        )
    } else {
      try {
        result = await callback()

        set(
          produce((state: ISignify) => {
            state.signifyTaskPerformed.push(operationName)
          })
        )
        resetTimeOut(set, get)
      } catch (ex) {}
    }
    return result
  },
  clearSignify: () => {
    client = null
    set(initialState)
  },
  getNotifications: async (last, limit) => {
    get()._passcodePrompter('getNotifications', { last, limit }, async () => {
      set(
        produce((_state: ISignify) => {
          _state.notifications.fetchingStatus = FETCHING_STATUS.FETCHING
        })
      )
      const notifications = await getNotifications(client, last, limit)
      set(
        produce((_state: ISignify) => {
          _state.notifications.notifications = notifications
          _state.notifications.fetchingStatus = FETCHING_STATUS.COMPLETE
          _state.signifyTaskPerformed.push('getNotifications')
        })
      )
    })
  },
  addNotificationProcess: (process: string) => {
    if (!get().notificationProcessingStatus.includes(process))
      set(
        produce((state: ISignify) => {
          state.notificationProcessingStatus = [
            ...state.notificationProcessingStatus,
            process
          ]
        })
      )
  },
  removeNotificationProcess: (process: string) => {
    set(
      produce((state: ISignify) => {
        state.notificationProcessingStatus =
          state.notificationProcessingStatus.filter(
            (notification) => notification !== process
          )
      })
    )
  },
  getIdentifiers: async () => {
    return get()._passcodePrompter('getIdentifiers', {}, async () => {
      set(
        produce((state: ISignify) => {
          state.aids.fetchingStatus = FETCHING_STATUS.FETCHING
        })
      )

      const aids = await getIdentifiers(client as SignifyClient)

      set(
        produce((state: ISignify) => {
          ;(state.aids.fetchingStatus = FETCHING_STATUS.COMPLETE),
            (state.aids.identifiers = aids)
          state.signifyTaskPerformed.push('getIdentifiers')
        })
      )
      return aids
    })
  },
  getIdentifierByAlias: async (alias) => {
    return get()._passcodePrompter(
      'getIdentifierByAlias',
      { alias },
      async () => {
        const identifier = await getIdentifier(client, alias)
        console.log('getIdentifierByAlias()', identifier)
        // set(
        //   produce((state: ISignify) => {
        //     state.aid = aid
        //   })
        // )
        return identifier
      }
    )
  },
  getContacts: async (group, filterField, filterValue) => {
    return get()._passcodePrompter(
      'getContacts',
      { group, filterField, filterValue },
      async () => {
        set(
          produce((state: ISignify) => {
            state.contacts.fetchingStatus = FETCHING_STATUS.FETCHING
          })
        )
        const contacts = await getContacts(
          client as SignifyClient,
          group,
          filterField,
          filterValue
        )
        set(
          produce((state: ISignify) => {
            ;(state.contacts.fetchingStatus = FETCHING_STATUS.COMPLETE),
              (state.contacts.contacts = contacts)
            state.signifyTaskPerformed.push('getContacts')
          })
        )
        return contacts
      }
    )
  },
  getContact: async (identifier) => {
    return get()._passcodePrompter('getContact', { identifier }, async () => {
      return await getContact(client, identifier)
    })
  },
  getKeyState: async (identifier) => {
    return get()._passcodePrompter('getKeyState', { identifier }, async () => {
      return await getKeyState(client, identifier)
    })
  },
  getRegistries: async (name) => {
    return get()._passcodePrompter('getKeyState', { name }, async () => {
      let regestries = []
      try {
        set(
          produce((state: ISignify) => {
            state.registries.fetchingStatus = FETCHING_STATUS.FETCHING
          })
        )
        regestries = await getRegistries(client, name)
        set(
          produce((state: ISignify) => {
            ;(state.registries.fetchingStatus = FETCHING_STATUS.COMPLETE),
              (state.registries.registries = regestries)
          })
        )
      } catch (ex) {
        set(
          produce((state: ISignify) => {
            state.registries.fetchingStatus = FETCHING_STATUS.FAILED
          })
        )
      }
      return regestries
    })
  },
  getOOBIS: async (alias, role) => {
    return get()._passcodePrompter('getOOBIS', { alias, role }, async () => {
      return await getOobis(client, alias, role)
    })
  },
  resolveOOBIS: async (oobi, alias) => {
    return get()._passcodePrompter(
      'resolveOOBIS',
      { oobi, alias },
      async () => {
        return resolveOobi(client, oobi, alias)
      }
    )
  },
  issueCredential: async (
    alias,
    registry,
    recipient,
    schema,
    credentialData,
    rules,
    source,
    _private
  ) => {
    return get()._passcodePrompter(
      'issueCredential',
      {
        alias,
        registry,
        recipient,
        schema,
        credentialData,
        rules,
        source,
        _private
      },
      async () => {
        return await issueCredential(
          client,
          alias,
          registry,
          recipient,
          schema,
          credentialData,
          rules,
          source,
          _private
        )
      }
    )
  },
  createIdentifier: async (alias, witnessAids) => {
    return get()._passcodePrompter('createIdentifier', { alias }, async () => {
      const createResp = await createIdentifier(client, alias, witnessAids)
      await get().getIdentifiers()
      return createResp
    })
  },
  deleteIdentifier: async (alias) => {
    return get()._passcodePrompter('deleteIdentifier', { alias }, async () => {
      const resp = await deleteIdentifier(client, alias)
      await get().getIdentifiers()
      return resp
    })
  },
  renameIdentifier: async (alias, newAlias) => {
    return get()._passcodePrompter(
      'renameIdentifier',
      { alias, newAlias },
      async () => {
        const resp = await renameIdentifier(client, alias, newAlias)
        await get().getIdentifiers()
        return resp
      }
    )
  },
  getCredentials: async (filter) => {
    return get()._passcodePrompter('getCredentials', { filter }, async () => {
      return await getCredentials(client, filter)
    })
  },
  generateChallenge: async () => {
    const response = await generateChallenge(client)
    if (response) return response?.words?.join(' ')
    else throw new Error('An error occurred while generating challenge')
  },
  resetSignifyStore: () => {
    set(initialState)
  },
  updateIdentifier(updatedIdentifier) {
    const identifiers = get().aids.identifiers.filter(
      (x) => x.prefix !== updatedIdentifier.prefix
    )
    set(
      produce((state: ISignify) => {
        state.aids.identifiers = [...identifiers, updatedIdentifier]
      })
    )
  }
})

export default signifyStore
