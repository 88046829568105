import constants from '@/config/constants'
import { GroupAid, Identifier } from '@/state/signify'
import { IOOBI } from '@/types/SignifyTypes'
import {
  getFirstWitnessFromEnds,
  getLocalMemberFromGroupAid,
  isGroupAid
} from '@/utils/aid'
import { getJWTToken } from '@/utils/auth'
import {
  CreateRegistryArgs,
  Saider,
  SignifyClient,
  Tier,
  Authenticater
} from 'signify-ts'
import { consoleLog } from '@/utils/console-logger'
import {
  getIdentifierTypeFromAlias,
  waitOperationWithRetries
} from './wallet/util'
import { formatUrlPath, hasKey } from '@/utils/common'
import { GroupMember } from './wallet/types'
import { CreateNativeAidRequest } from '@/api/origin-agent-svc'

const attachInterceptor = (signifyClient, aidHab) => {
  const originalFetch = fetch

  const fetchWithCustomHeader = async (
    url: RequestInfo | URL,
    options: RequestInit | undefined
  ): Promise<Response> => {
    const optionsWithCustomHeader = { ...options }
    const headers = optionsWithCustomHeader?.headers || {}
    const body = optionsWithCustomHeader?.body
    let path = ''
    try {
      const path = new URL(url.toString()).pathname
    } catch (error) {
      //ignoring -next urls
    }

    let modifiedHeaders = new Headers()

    if (!(headers instanceof Headers))
      Object.keys(headers).forEach((key) => {
        // @ts-ignore
        modifiedHeaders.set(key, headers[key])
      })
    else {
      for (const [key, value] of headers.entries())
        modifiedHeaders.set(key, value)
    }

    if (
      !modifiedHeaders.has('Signify-Resource') &&
      !url.toString().includes('cognito')
    ) {
      modifiedHeaders = await signHeaders(
        signifyClient,
        aidHab,
        optionsWithCustomHeader.method,
        path,
        modifiedHeaders,
        body
      )

      // Cognito header (will be removed in the future)
      const jwtToken = getJWTToken()
      jwtToken && modifiedHeaders.set('Authorization', jwtToken)
    }
    optionsWithCustomHeader.headers = modifiedHeaders

    return originalFetch(url, optionsWithCustomHeader)
  }

  window.fetch = fetchWithCustomHeader
}

export const initWallet = async (passCode: string) => {
  const { ready, SignifyClient } = await import('signify-ts')
  await ready()
  const client = new SignifyClient(constants.KERIA_URL, passCode)

  const [evt, sign] = client.controller?.event ?? []
  return {
    icp: evt.ked,
    sig: sign.qb64,
    stem: client.controller?.stem,
    pidx: 1,
    tier: client.controller?.tier
  }
}

export const initClient = async (passCode: string, originAid?: string) => {
  const { ready, SignifyClient } = await import('signify-ts')
  await ready()
  const client = new SignifyClient(
    constants.KERIA_URL,
    passCode,
    Tier.low,
    constants.SIGNIFY_BOOT
  )
  try {
    await client.connect()

    if (!originAid) {
      if (!constants.WITNESS_AIDS) {
        throw new Error('Witnesses are not configured for the identifiers')
      }
      const witnessAids = constants.WITNESS_AIDS.split(',').map((item) =>
        item.trim()
      )

      const op: any = await createIdentifier(
        client,
        'me-as-user-at-origin',
        witnessAids
      )
      originAid = op.i
    }
    const aids = await client.identifiers().list()
    const userAid = aids.aids.find((aid) => aid.prefix === originAid)
    let userAidHab = await client.identifiers().get(userAid.name)
    attachInterceptor(client, userAidHab)
  } catch (ex) {
    console.error('INIT_CLIENT_ERROR::: ' + ex)
  }
  return client
}

export const getIdentifiers = async (
  client: SignifyClient,
  start: number = 0,
  end: number = 100
): Promise<Identifier[]> => {
  let result = await client.identifiers().list(start, end)
  let identifiers: Identifier[] = []
  for (let i = 0; i < result?.['aids']?.length; i++) {
    const identif: Identifier = result['aids'][i]
    let isGroup = isGroupAid(identif)
    let group: GroupAid = null
    let rawData = null
    if (isGroup === true) {
      rawData = await client.identifiers().get(identif.name)
      let members = await getGroupMembersSigningThreshold(client, identif.name)
      let localAid = getLocalMemberFromGroupAid(identif)
      let witness = getFirstWitnessFromEnds(
        members?.find((x) => x.aid === localAid?.prefix)?.ends
      )
      group = {
        name: identif.name,
        prefix: identif.prefix,
        localAid: localAid,
        members: members,
        minSignersThreshold:
          members?.length > 0 ? members[0].minSignersThreshold : 0,
        witnessOobi: witness?.http
          ? formatUrlPath(witness.http, `oobi/${identif.prefix}`)
          : null
      }
    }

    const identifier: Identifier = {
      name: identif.name,
      prefix: identif.prefix,
      isMultisig: isGroup,
      group: group,
      raw: rawData
    }

    identifiers.push(identifier)
  }
  return identifiers
}

export const getIdentifier = async (client: SignifyClient, alias: string) => {
  return await client.identifiers().get(alias)
}

export const createIdentifier = async (
  client: SignifyClient,
  alias: string,
  witnessAids: string[]
) => {
  if (witnessAids.length === 0) {
    throw new Error('Witnesses are not configured for the identifiers')
  }

  let args: any = {
    wits: witnessAids,
    toad: witnessAids.length
  }
  let res = await client.identifiers().create(alias, args)
  let op = await waitOperationWithRetries(client, await res.op(), 60)

  await addEndRole(client, alias, client!.agent!.pre, 'agent')

  await createRegistry(client, alias, `reg-${alias}`)

  return op['response']
}

export const renameIdentifier = async (
  client: SignifyClient,
  alias: string,
  newAlias: string
): Promise<any> => {
  return await client.identifiers().rename(alias, newAlias)
}

export const deleteIdentifier = async (
  client: SignifyClient,
  alias: string
): Promise<any> => {
  return await client.identifiers().delete(alias)
}

export const addEndRole = async (
  client: SignifyClient,
  alias: string,
  prefix: string,
  role: string
) => {
  try {
    let res = await client.identifiers().addEndRole(alias, role, prefix)
    await waitOperationWithRetries(client, await res.op(), 30)
    //await delay(2000)
    const oobiRes = await client.oobis().get(alias, role)
    if (oobiRes && oobiRes?.oobis.length < 1) {
      await client.identifiers().addEndRole(alias, role, prefix)
    }
  } catch (ex) {
    console.error('Error in addEndRole', ex)
  }
}

export const getOobis = async (
  client: SignifyClient,
  alias: string,
  role: string = 'agent'
): Promise<IOOBI> => {
  return await client.oobis().get(alias, role)
}

export const getKeyState = async (
  client: SignifyClient,
  identifier: string
) => {
  return await client.keyStates().get(identifier)
}

export const resolveOobi = async (
  client: SignifyClient,
  oobi: string,
  name: string,
  maxRetries: number = 50
) => {
  let operation = await client.oobis().resolve(oobi, name)
  let op = await waitOperationWithRetries(client, operation, maxRetries)
  return op['response']
}

export const getContacts = async (
  client: SignifyClient,
  group?: string,
  filterField?: string,
  filterValue?: string
) => {
  return await client.contacts().list(group, filterField, filterValue)
}

export const getContact = async (client: SignifyClient, identifier: string) => {
  return await client.contacts().get(identifier)
}

export const addContact = async (
  client: SignifyClient,
  identifier: string,
  info: any
) => {
  return await client.contacts().add(identifier, info)
}

export const updateContact = async (
  client: SignifyClient,
  identifier: string,
  info: any
) => {
  return await client.contacts().update(identifier, info)
}

export const deleteContact = async (
  client: SignifyClient,
  identifier: string
) => {
  return await client.contacts().delete(identifier)
}

/**
 * Generate a random challenge word list based on BIP39.
 * @async
 * @returns {Promise<any>} A promise to the list of random words
 */
export const generateChallenge = async (client: SignifyClient) => {
  return client.challenges().generate(128)
}

/**
 * Respond to a challenge by signing a message with the list of words
 * @async
 * @param {SignifyClient} client Signify Client
 * @param {string} alias Alias of the AID of the respondent
 * @param {string} recipientAid AID of the recipient of the response
 * @param {Array<string>} words List of words to embed in the signed response
 * @returns {Promise<Response>} A promise to the result of the response
 */
export const respondToChallenge = async (
  client: SignifyClient,
  alias: string,
  recipientAid: string,
  words: string[]
) => {
  return client.challenges().respond(alias, recipientAid, words)
}

/**
 * Ask Agent to verify a given sender signed the provided words
 * @param {SignifyClient} client Signify Client
 * @param {string} alias Name or alias of the identifier
 * @param {string} source AID that was challenged
 * @param {Array<string>} words List of challenge words to check for
 * @returns {Promise<Response>} A promise to the result
 */
export const verifyChallengeResponse = async (
  client: SignifyClient,
  alias: string,
  pre: string,
  words: string[]
) => {
  consoleLog('verify challenge response using aid  ' + alias)
  let operation = await client.challenges().verify(pre, words)
  const operationsInstance = client.operations()
  while (!operation['done']) {
    operation = await operationsInstance.get(operation['name'])
    await new Promise((resolve) => setTimeout(resolve, 1000))
  }
}

/**
 * Mark challenge response as signed and accepted
 * @param {SignifyClient} client Signify Client
 * @param {string} name Name or alias of the identifier
 * @param {string} source AID that was challenged
 * @param {string} said qb64 AID of exn message representing the signed response
 * @returns {Promise<Response>} A promise to the result
 */
export const acceptChallengeResponse = async (
  client: SignifyClient,
  alias: string,
  pre: string,
  said: string
) => {
  consoleLog('accept challenge response using aid ' + alias)
  return await client.challenges().responded(pre, said)
}

export const getSchemas = async (client: SignifyClient) => {
  return await client.schemas().list()
}

export const getSchema = async (client: SignifyClient, said: string) => {
  return await client.schemas().get(said)
}

export const getRegistries = async (client: SignifyClient, name: string) => {
  return await client.registries().list(name)
}

export const createRegistry = async (
  client: SignifyClient,
  alias: string,
  registryName: string,
  nonce?: string
) => {
  let args: CreateRegistryArgs = {
    name: alias,
    registryName: registryName,
    nonce: nonce
  }
  let res = await client.registries().create(args)
  let op = await waitOperationWithRetries(client, await res.op(), 60)
  return op['response']
}

export const getCredentials = async (
  client: SignifyClient,
  filter: object,
  limit: number = 100
): Promise<any[]> => {
  if (filter !== undefined && filter !== null)
    return await client.credentials().list({ filter: filter, limit: limit })
  else return await client.credentials().list({ limit: limit })
}

export const issueCredential = async (
  client: SignifyClient,
  alias: string,
  registy: string,
  recipient: string,
  schema: string,
  credentialData: any,
  rules: any | undefined,
  source: any | undefined,
  _private?: boolean
) => {
  const result = await client.credentials().issue({
    issuerName: alias,
    registryId: registy,
    schemaId: schema,
    recipient: recipient,
    data: credentialData,
    rules: rules && Saider.saidify({ d: '', rules })[1],
    source: source && Saider.saidify({ d: '', source })[1]
  })
  return result
}

export const presentCredential = async (
  client: SignifyClient,
  alias: string,
  said: string,
  recipient: string,
  include?: boolean
) => {
  return await client.credentials().present(alias, said, recipient, include)
}

export const requestCredential = async (
  client: SignifyClient,
  alias: string,
  recipient: string,
  schema: string,
  issuer: string
) => {
  return await client.credentials().request(alias, recipient, schema, issuer)
}

export const getNotifications = async (
  client: SignifyClient,
  start: number = 0,
  end: number = 200
) => {
  return await client.notifications().list(start, end)
}

export const markNotification = async (client: SignifyClient, said: string) => {
  try {
    return await client.notifications().mark(said)
  } catch (ex) {}
}

export const deleteNotification = async (
  client: SignifyClient,
  said: string
) => {
  return await client.notifications().delete(said)
}

export const getGroupMembersSigningThreshold = async (
  client: SignifyClient,
  alias: string
): Promise<GroupMember[]> => {
  try {
    let aid = await client.identifiers().get(alias)
    if (hasKey(aid, 'group')) {
      let signingThreshold = aid?.state?.kt
      let minSigners = findMinimumSignersThreshold(signingThreshold)
      let members = await client.identifiers().members(alias)

      let groupMembers: GroupMember[] = members?.signing?.map(
        (item, index) =>
          ({
            aid: item.aid,
            ends: item.ends,
            weightage: Array.isArray(signingThreshold)
              ? signingThreshold[index]
              : null,
            weighted: Array.isArray(signingThreshold),
            minSignersThreshold: minSigners
          } as GroupMember)
      )

      groupMembers.sort((a, b) => a.aid.localeCompare(b.aid))
      return groupMembers
    } else return null
  } catch (ex) {
    console.error('ERROR_IN_getGroupMembersSigningThreshold : ', ex)
    return null
  }
}

/**
 * Calculates the minimum threshold from a given current signing threshold(kt), which can be either a single string or an array of strings and numbers.
 * The function determines the smallest set of elements from the array whose sum is equal to or exceeds 1.
 *
 * @param {string | (string | number)[]} kt - current signing threshold(kt), it can be a number, or an array of fractions.
 * @returns {number} The minimum number of members of group AID that must sign.
 *
 * Example Usage:
 * findMinSignersThreshold("2"); // returns 2
 * findMinSignersThreshold(["1/2", "1/4", "1", "3"]); // returns 1
 * findMinSignersThreshold(["1/3", "1/3", "1/3", "1/3"]); // returns 3
 */
export function findMinimumSignersThreshold(
  kt: string | (string | number)[]
): number {
  try {
    if (typeof kt === 'string') {
      return Number(kt)
    }

    const numericalFractions = kt.map((fraction) => {
      if (typeof fraction === 'number') {
        return fraction
      } else if (typeof fraction === 'string' && fraction.includes('/')) {
        const [numerator, denominator] = fraction.split('/').map(Number)
        return numerator / denominator
      } else {
        return Number(fraction)
      }
    })

    numericalFractions.sort((a, b) => b - a)

    let sum = 0
    let threshold = 0

    for (const fraction of numericalFractions) {
      sum += fraction
      threshold++
      if (sum >= 1) {
        break
      }
    }

    return threshold
  } catch (x) {
    return -1
  }
}

export const generateAgentSvcIdentifierPayload = async (
  signifyClient: SignifyClient,
  alias: string,
  userId: string,
  orgId: string,
  role: string = 'agent'
) => {
  const aidResult = await getIdentifier(signifyClient, alias)

  const oobiResult = await getOobis(signifyClient, alias, role)

  const payload: CreateNativeAidRequest = {
    aid: aidResult.prefix,
    alias: aidResult.name,
    oobi: oobiResult.oobis,
    orgId: orgId,
    type: getIdentifierTypeFromAlias(aidResult.name, userId),
    isMultisig: false,
    userId: userId
  }

  return payload
}

export const getAidDetail = async (
  signifyClient: SignifyClient,
  alias: string,
  role: string = 'agent'
) => {
  const aidResult = await getIdentifier(signifyClient, alias)
  let isGroup = isGroupAid(aidResult)
  const oobiResult = await getOobis(signifyClient, alias, role)
  const aidDetail = {
    aid: aidResult.prefix,
    alias: aidResult.name,
    oobi: oobiResult.oobis,
    isMultisig: isGroup
  }
  return aidDetail
}

export const signHeaders = async (
  client: SignifyClient,
  aidHab: any,
  method: string,
  path: string,
  headers: Headers,
  body: BodyInit
): Promise<Headers> => {
  try {
    const keeper = client?.manager!.get(aidHab)
    const authenticator = new Authenticater(
      keeper.signers[0],
      keeper.signers[0].verfer
    )
    headers.set('Signify-Resource', aidHab.prefix)
    headers.set(
      'origin-date',
      new Date().toISOString().replace('Z', '000+00:00')
    )
    if (!body) {
      body = Buffer.from('')
    }
    const buffer = await crypto.subtle.digest('SHA-256', body as BufferSource)

    var binary = ''
    new Uint8Array(buffer).forEach(function (byte) {
      binary += String.fromCharCode(byte)
    })

    const digest = `sha-256=:${window
      .btoa(binary)
      .replace(/\//g, '_')
      .replace(/\+/g, '-')
      .replace(/=/g, '')}:`

    headers.set('content-digest', digest)

    const fields = [
      '@method',
      '@path',
      'origin-date',
      'signify-resource',
      'content-digest'
    ]

    const signed_headers = authenticator.sign(headers, method, path, fields)
    return signed_headers
  } catch (error) {
    return headers
  }
}
